<template>
  <main class="overflow-hidden">
    <!-- Navigation -->
    <BlockNavigation
      v-if="route.name !== 'orders-id'"
      :user="user" />

    <!-- Page Slot -->
    <slot />
    <!-- Login Modal -->
    <ModalLogin v-if="showLoginModal" />
    <Intercom />
  </main>
</template>

<script setup lang="ts">
const user = useSupabaseUser()
const route = useRoute()
const showLoginModal = useState('showLoginModal', () => false)

useSeoMeta({
  title: 'PicUp | The Easiest Way To Move Your Things',
  description: 'Picup offer services from small moves, to business deliveries and waste removal. Get an instant quote today!',
  ogImage: '/social-preview.png',
  ogUrl: 'https://picup.com.au',
})

onMounted(() => {
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: 5231375, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
})
</script>
