<template>
  <a
    href="tel:1300174287"
    class="font-medium md:text-lg">
    <div class="max-md:hidden">
      <Icon
        name="heroicons:phone-solid"
        class="size-4" />
    </div>

    1300 174 287
  </a>
</template>

<script setup lang="ts">
const { gtag } = useGtag()

onMounted(async () => {
  await nextTick()
  try {
    gtag('event', 'phone_conversion', {
      phone_conversion_number: '1300 174 287',
    })
  }
  catch (error) {
    console.warn('Error with phone conversion number', error)
  }
})
</script>
