<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/10 p-4 backdrop-blur-sm">
    <form @submit.prevent="signIn" class="relative z-20 grid w-full max-w-[500px] gap-4 rounded-xl border bg-white p-8 shadow-2xl">
      <Icon name="heroicons:x-mark" @click="hideLoginModal" class="absolute right-4 top-4 h-8 w-8 cursor-pointer rounded-full bg-gray-100 p-1" />
      <h2 class="text-2xl font-bold">Sign In</h2>
      <div class="flex flex-col">
        <label for="modal-email" class="mb-1.5 text-zinc-500">Email Address</label>
        <input
          id="modal-email"
          v-model="email"
          type="email"
          tabindex="1"
          placeholder="Enter email address"
          class="flex-1 rounded-lg border border-zinc-200 px-4 py-3 shadow"
          autocomplete="email"
          required />
      </div>
      <div class="flex flex-col">
        <div class="flex justify-between">
          <label for="modal-password" class="mb-1.5 text-zinc-500">Password</label>
          <NuxtLink to="/forgot-password" class="text-center text-sky-900 hover:underline" target="_blank" tabindex="4">Forgot password?</NuxtLink>
        </div>
        <input
          id="modal-password"
          type="password"
          placeholder="Enter Password"
          tabindex="2"
          v-model="password"
          class="rounded-lg border border-zinc-200 px-4 py-3 shadow"
          autocomplete="current-password"
          required />
      </div>

      <div v-if="errors" class="rounded-lg border-red-400 bg-red-50 px-4 py-2 text-red-800">
        <p>{{ errors }}</p>
      </div>

      <div class="mt-2 rounded-xl bg-blue-50 p-4">
        <div class="font-semibold">Don't have an account?</div>
        <p class="mt-0.5">Complete first your order to create an account.</p>
      </div>

      <Button type="submit" class="mt-4 w-full" size="xl" tabindex="3">Sign In</Button>
    </form>
    <div @click="hideLoginModal" tabindex="-1" role="dialog" class="fixed inset-0 z-10" />
  </div>
</template>

<script setup>
  const showLoginModal = useState('showLoginModal', () => false)
  const email = ref('')
  const password = ref('')
  const errors = ref('')

  function hideLoginModal() {
    showLoginModal.value = false
  }

  async function signIn() {
    const { data, error } = await useSupabaseClient().auth.signInWithPassword({
      email: email.value,
      password: password.value
    })

    if (error) {
      errors.value = error.message
      return
    }

    hideLoginModal()
  }
</script>
